import React, { useState } from 'react'
import PropTypes from 'prop-types'
import CommonLink from '../CommonLink'

const svgContainer = {
  position: 'relative',
  height: '53px',
  width: '100%',
}

const svgStyle = {
  position: 'absolute',
  height: '100%',
  width: '100%',
  left: 0,
  top: 0,
  fillRule: 'evenodd',
  clipRule: 'evenodd',
  strokeLinejoin: 'round',
  strokeMiterlimit: '1.41421',
}

const textStyle = {
  fontFamily: 'Oswald',
  textTransform: 'uppercase',
  fontWeight: '500',
  fontSize: '1.5rem',
}

const SVGButton = props => {
  const [hover, setHover] = useState(false)
  const { to, bgColor, bgColorHover, textColor, textColorHover, label } = props
  return (
    <CommonLink to={to}>
      <button
        onMouseOver={() => setHover(true)}
        onFocus={() => setHover(true)}
        onMouseOut={() => setHover(false)}
        onBlur={() => setHover(false)}
        type="button"
        className="w-40"
      >
        <div style={svgContainer}>
          <svg
            viewBox="0 0 185 62"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            xmlSpace="preserve"
            className="overflow-y-hidden"
            style={svgStyle}
          >
            <path
              d="M185,31l-30.146,61.835l0,-123.67l30.146,61.835Z"
              fill={hover ? bgColorHover : bgColor}
              fillOpacity=".70"
            />
            <path
              d="M174.59,30.998l-18.186,36.936l0,-73.873l18.186,36.937Z"
              fill={hover ? bgColorHover : bgColor}
            />
            <rect
              x="-0.006"
              y="-0.02"
              width="159.28"
              height="62.032"
              fill={hover ? bgColorHover : bgColor}
            />
            <text
              style={textStyle}
              textAnchor="middle"
              x="46%"
              y="65%"
              fill={hover ? textColorHover : textColor}
            >
              {label}
            </text>
          </svg>
        </div>
      </button>
    </CommonLink>
  )
}

SVGButton.propTypes = {
  to: PropTypes.string,
  label: PropTypes.string,
  bgColor: PropTypes.string,
  bgColorHover: PropTypes.string,
  textColor: PropTypes.string,
  textColorHover: PropTypes.string,
}

export default SVGButton
