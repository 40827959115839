import React from 'react'
import PropTypes from 'prop-types'
import { v4 } from 'uuid'
import Image from './Image'
import SVGButton from './SVG/SVGButton'
import Content, { HTMLContent } from './Content'

const CardPattern = require('../../static/img/Patterns/cardpattern.png')

const ThemeCards = ({ themecards }) => (
  <ul className="flex flex-wrap justify-center lg:mx-20 xl:mx-4 2xl:mx-20">
    {themecards.map(card => (
      <li
        className="xl:p-3 pb-8 w-full lg:w-1/2 xl:w-1/3 text-center flex flex-col items-center"
        key={v4()}
      >
        <div
          className="w-card overflow-hidden shadow-lg text-white flex-auto bg-cover"
          style={{ backgroundImage: `url(${CardPattern})` }}
        >
          <div className="px-16 py-6 h-56">
            <p className="text-xl mb-2 font-header font-hairline uppercase">
              {card.theme}
            </p>
            <hr className="mx-auto h-1/2 w-40 bg-white" />
            <p className="text-2xl font-header uppercase">{card.title}</p>
          </div>
          <Image imageInfo={card.cardimage} />
          <div className="py-6 h-30">
            <SVGButton
              label={card.linklabel}
              to={card.link}
              bgColor="white"
              bgColorHover="#189CD8"
              textColor="#189CD8"
              textColorHover="white"
            />
          </div>
        </div>
      </li>
    ))}
  </ul>
)

ThemeCards.propTypes = {
  themecards: PropTypes.arrayOf(
    PropTypes.shape({
      theme: PropTypes.string,
      title: PropTypes.string,
      linklabel: PropTypes.string,
      link: PropTypes.link,
      cardimage: PropTypes.object,
    })
  ),
}

export default ThemeCards
