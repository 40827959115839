import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Markdown from 'react-markdown'
import LinkCards from '../components/LinkCards'
import ThemeCards from '../components/ThemeCards'
import Layout from '../components/Layout'
import SVGBorderButton from '../components/SVG/SVGBorderButton'
import BannerImage from '../components/BannerImage'
import SectionWithAside from '../components/SectionWithAside'
import Container from '../components/Container'

export const IndexPageTemplate = ({
  bannerimage,
  title,
  subheading,
  sectionwithaside,
  themecards,
  section,
  linkcards,
}) => (
  <>
    <div className="relative">
      <BannerImage imageInfo={bannerimage}>
        <div className="absolute bottom-0 md/lg:top-0 w-full h-full">
          <div className="flex h-full leading-none flex-col float-right md:mx-5 lg:mx-16 xl:mx-20 sm:mt-4 md:mt-6 lg:mt-8 xl:mt-16 m-4 md:m-8">
            <h1 className="font-header text-purple text-5xl lg:text-7xl self-end">
              METRO VISION
            </h1>
            <h2 className="font-header gatsby-banner-theme-text-color text-blue text-4xl md:text-4xl lg:text-5xl font-light leading-none block lg:block self-end xl:pt-3">
              {title}
            </h2>
            <p className="font-body text-base text-blue-darkest leading-relaxed w-56 md:w-56 lg:w-72 pt-4 md:pt-2 xl:pt-8 self-end">
              {subheading}
            </p>
          </div>
        </div>
      </BannerImage>
    </div>
    <Container className="pt-12 sm:pt-16 md:pt-32 flex flex-col lg:flex-row">
      <SectionWithAside content={sectionwithaside} />
    </Container>
    <Container className="pt-12">
      <ThemeCards themecards={themecards} />
    </Container>
    <Container
      outerClassName="bg-teal"
      className="py-12 px-12 md:mt-24 md:py-18 md:px-40 lg:px-48  text-white text-center"
    >
      <div className="pb-4">
        <h1 className="md:inline-block text-3xl font-header font-light uppercase pr-2">
          {section.sectiontitle}
        </h1>
        <h1 className="md:inline-block text-3xl font-header font-normal uppercase">
          {section.title}
        </h1>
      </div>
      <Markdown
        className="font-body pb-6 markdown-section"
        source={section.body}
      />
      <SVGBorderButton
        label={section.linklabel}
        to={section.link}
        bgColor="white"
        bgColorHover="#189CD8"
        borderColor="white"
        borderColorHover="white"
        textColor="#189CD8"
        textColorHover="white"
      />
      <Markdown
        className="font-body pt-6 markdown-section"
        source={section.footnote}
      />
    </Container>
    <Container className="py-20 md:py-24">
      <LinkCards linkcards={linkcards} />
    </Container>
  </>
)

IndexPageTemplate.propTypes = {
  bannerimage: PropTypes.object,
  title: PropTypes.string,
  subheading: PropTypes.string,
  sectionwithaside: PropTypes.object,
  themecards: PropTypes.array,
  linkcards: PropTypes.array,
  section: PropTypes.object,
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout
      ogtitle={frontmatter.ogtitle}
      ogdescription={frontmatter.ogdescription}
      ogimage={frontmatter.ogimage}
    >
      <IndexPageTemplate
        bannerimage={frontmatter.bannerimage}
        title={frontmatter.title}
        subheading={frontmatter.subheading}
        sectionwithaside={frontmatter.sectionwithaside}
        themecards={frontmatter.themecards}
        linkcards={frontmatter.linkcards}
        section={frontmatter.section}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        opengraph {
          ogtitle
          ogdescription
          ogimage {
            childImageSharp {
              fluid(maxWidth: 300, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        title
        subheading
        bannerimage {
          alt
          desktopimage {
            childImageSharp {
              fluid(maxWidth: 1600, quality: 80) {
                ...GatsbyImageSharpFluid
                src
              }
            }
          }
          # tabletimage {
          #   childImageSharp {
          #     fluid(maxWidth: 850, quality: 80) {
          #       ...GatsbyImageSharpFluid
          #       src
          #     }
          #   }
          # }
          mobileimage {
            childImageSharp {
              fluid(maxWidth: 833, quality: 80) {
                ...GatsbyImageSharpFluid
                src
              }
            }
          }
        }
        sectionwithaside {
          title
          body
          asidetitle
          asidebody
        }
        themecards {
          theme
          title
          linklabel
          link
          cardimage {
            image {
              childImageSharp {
                fluid(maxWidth: 400, quality: 80) {
                  ...GatsbyImageSharpFluid
                  src
                }
              }
            }
            alt
          }
        }
        section {
          sectiontitle
          title
          body
          linklabel
          link
          footnote
        }
        linkcards {
          title
          cardimage {
            image {
              childImageSharp {
                fluid(maxWidth: 200, quality: 80) {
                  ...GatsbyImageSharpFluid
                  src
                }
              }
            }
            alt
          }
          body
          linklabel
          link
        }
      }
    }
  }
`
